import FormInput from '@/form/FormInput'
import useRequiredComponent from './useRequiredComponent'
import { useTranslations } from 'next-intl'
import type { AddressFieldsProps } from './types'

function StreetField({ required }: AddressFieldsProps) {
  const t = useTranslations('nextjs.donate.attributes')
  const FieldComponent = useRequiredComponent(required)
  const label = t('street')

  return <FieldComponent name="street" title={label} as={FormInput} />
}

export default StreetField
