import Image from 'next/image'
import TextLink from '@/components/TextLink'
import styles from './HeroImage.module.css'
import { Heading } from '@betterplace/design-system/client'
import { HeroImageProps } from './types'

function HeroImage({ receiverName, receiverProfilePicture, ...props }: HeroImageProps) {
  return (
    <div className={styles.container}>
      <Image src={receiverProfilePicture} alt="" width={640} height={333} className={styles.image} />
      {props.linked ? (
        <TextLink href={props.receiverUrl} className={styles.heading}>
          <Heading level="h100">{receiverName}</Heading>
        </TextLink>
      ) : (
        <Heading level="h100" className={styles.heading}>
          {receiverName}
        </Heading>
      )}
    </div>
  )
}

export default HeroImage
