'use client'

import HolderTitle from '../HolderTitle'
import TextLink from '@/components/TextLink'
import styles from './HolderHeadline.module.css'
import { Heading, LayoutCluster } from '@betterplace/design-system/client'
import { useDonationFormContext, useDonationFormValues } from '@/donationForm/_dependencies/helpers'
import { useEffect, useState } from 'react'
import { useTranslations } from 'next-intl'

function HolderHeadline() {
  const t = useTranslations('nextjs.donate.donor_headline')
  const { setValue } = useDonationFormContext()
  const [companyDonation, receiptDesired] = useDonationFormValues(['_company_donation', 'receipt_desired'])
  const headline = companyDonation ? <Heading level="h200">{t('company')}</Heading> : <HolderTitle />
  const [disabled, setDisabled] = useState(true)
  useEffect(() => {
    setDisabled(false)
  }, [])
  const switchLabel = companyDonation ? t('switch_to_personal') : t('switch_to_company')
  return (
    <LayoutCluster>
      <div className="donations-form-holder-label">{headline}</div>
      <div className={styles.linkWrapper}>
        {/* TODO: We can switch it to router based proper link after updating Next.js to 14.2+, or implement a toggle component instead */}
        <TextLink
          href="javascript:void(0)"
          className={styles.link}
          onClick={(evt) => {
            if (disabled) return evt.preventDefault()
            setValue('_company_donation', !companyDonation)
            if (!receiptDesired) {
              setValue('receipt_desired', true)
            }
          }}
          data-axe-expect="color-contrast"
        >
          {switchLabel}
        </TextLink>
      </div>
    </LayoutCluster>
  )
}

export default HolderHeadline
